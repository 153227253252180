import { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import image1 from "../images/sh.png";
import image2 from "../images/ep.jpg";

function Power() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  return (
    <>
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5">
        <div data-aos="zoom-in">
          <h3 className="text-2xl md:text-4xl font-medium pb-1">
            Power Plant EPC
          </h3>
          <h4 className="font-medium">
            <Link to="/" className="text-primary">
              Home
            </Link>{" "}
            / Power
          </h4>
        </div>
      </div>

      <div className="myContainer">
        <h2
          className="md:text-center text-lg md:text-2xl pb-10 font-semibold text-black mb-3 pt-6"
          data-aos="zoom-in"
        >
          Customized solutions and services <br className="hidden lg:flex" />{" "}
          for flexibility and freedom from the O&M Engineering • Procurement •
          Construction
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-10 text-secondary text-justify">
          <div>
            <img
              src={image2}
              alt=" Power Plant EPC"
              className="rounded rounded-md mb-4 h-80"
            />

            <div data-aos="fade-up">
              <h2 className="font-semibold text-black mb-3 pt-5 text-lg md:text-2xl ">
                Turnkey EPC Solutions
              </h2>
              <p>
                VERTEVILLE’s commercial and site experience allows for seamless
                integration of the various components of a power project.
                VERTEVILLE works with private, government, or joint entities to
                provide the services necessary to meet their sophisticated
                requirements. VERTEVILLE is agile and flexible in designing and
                building turnkey power plants.
              </p>
              <p className="pt-3">
                Projects are offered with a complete engineering, procurement,
                and construction (EPC) capability, along with the management of
                engineering disciplines and construction trades (EPCM).
                VERTEVILLE can also offer a provision for operations and
                maintenance services after the build.
              </p>

              <h2 className="font-semibold text-black mb-3 pt-5 text-lg md:text-2xl ">
                Fast-Track Services
              </h2>
              <p>
                VERTEVILLE specializes in a modular plant design that enables
                plants to be built rapidly to fulfill fast-track project
                obligations. VERTEVILLE has executed complete power plant
                projects in as little as six months from contract signing to
                commissioning.
              </p>
            </div>
          </div>
          <div>
            <h2 className="font-semibold text-black mb-3 pt-5 text-lg md:text-2xl ">
              Turbine Specialists
            </h2>
            <div data-aos="fade-up">
              <p>
                VERTEVILLE can design a power plant utilizing a wide-range of
                industrial or aeroderivative gas-turbine generator sets for
                simple or combine cycle operation. In addition, provisions for a
                later conversion to a combined cycle operation can be built into
                the plant design. Open architecture, PLC-based controls include:
              </p>

              <ol className="list-disc py-3 ml-5">
                <li>Plant-wide (DCS or SCS)</li>
                <li>Balance-of-plant</li>
                <li>Turbo-generator</li>
                <li>Turbo-compressor and turbo-pump</li>
                <li>New and retrofit systems</li>
              </ol>

              <p>
                The VERTEVILLE turbine technicians are O&M trained specialists
                who have extensive knowledge of gas and steam turbines and their
                peripheral systems.
              </p>
              <h2 className="font-semibold text-black mb-3 pt-5 text-lg md:text-2xl ">
                Supporting Services
              </h2>
              <ol className="list-disc py-3 ml-5">
                <li>Global engineering field services</li>
                <li>Site surveys</li>
                <li>Fuel valve upgrade and installation</li>
                <li>HMI upgrades</li>
              </ol>
            </div>
            <img
              src={image1}
              alt=" Power Plant EPC"
              className="rounded rounded-md mt-4 h-80"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Power;
