import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="bg-black">
      <div className="myContainer py-10">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 text-white mb-12">
          <div>
            <h3 className="uppercase text-xl">Opening Hours</h3>
            <div className="w-10 h-1 bg-primary mt-3 mb-7"></div>
            <ul className="flex flex-col gap-3">
              <li className="flex justify-between">
                <span>Monday</span>
                <span>8AM - 5PM</span>
              </li>
              <li className="flex justify-between">
                <span>Tuesday</span>
                <span>8AM - 5PM</span>
              </li>
              <li className="flex justify-between">
                <span>Wednesday</span>
                <span>8AM - 5PM</span>
              </li>
              <li className="flex justify-between">
                <span>Thursday</span>
                <span>8AM - 5PM</span>
              </li>
              <li className="flex justify-between">
                <span>Friday</span>
                <span>8AM - 5PM</span>
              </li>
              <li className="flex justify-between">
                <span>Saturday and Sunday</span>
                <span>Closed</span>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="uppercase text-xl">Keep in Touch</h3>
            <div className="w-10 h-1 bg-primary mt-3 mb-7"></div>
            <ul className="flex flex-col gap-3">
              <li className="flex gap-4">
                <span>
                  <i className="far fa-clock text-primary"></i>
                </span>
                <span>8AM - 5PM</span>
              </li>
              <li className="flex gap-4">
                <span>
                  <i className="fas fa-phone-alt text-primary"></i>
                </span>
                <span>
                  <a
                    href="tel:+234 807 769 1710"
                    className="hover:text-primary"
                  >
                    +234 807 769 1710
                  </a>
                </span>
              </li>
              <li className="flex gap-4">
                <span>
                  <i className="far fa-envelope text-primary"></i>
                </span>
                <span>
                  <a
                    href="mailto:info@vertevilleenergy.com"
                    className="hover:text-primary"
                  >
                    info@vertevilleenergy.com
                  </a>
                </span>
              </li>
              <li className="flex gap-4">
                <span>
                  <i className="fas fa-video text-primary"></i>
                </span>
                <span>+234 909 040 9351</span>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="uppercase text-xl">EXPLORE OUR WORK</h3>
            <div className="w-10 h-1 bg-primary mt-3 mb-7"></div>
            <ul className="flex flex-col gap-3">
              <li>
                <Link to="/power" className="hover:text-primary">
                  Power Plant EPC
                </Link>
              </li>
              <li>
                <Link to="/industry" className="hover:text-primary">
                  Industrial GT Solutions
                </Link>
              </li>
              <li>
                <Link to="/replacement" className="hover:text-primary">
                  Replacement Units
                </Link>
              </li>
              <li>
                <Link to="/steam" className="hover:text-primary">
                  Turbine Compressor Services
                </Link>
              </li>
              <li>
                <Link to="/transformer" className="hover:text-primary">
                  Transformer Reliability
                </Link>
              </li>
              <li>
                <Link to="/operation" className="hover:text-primary">
                  Operation and Maintenance
                </Link>
              </li>
              <li>
                <Link to="/authorized" className="hover:text-primary">
                  Authorized Gas Shipper
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="text-white text-center pt-8">
          <Link to="/policy" className="hover:text-primary">
            Privacy Policy
          </Link>
          &nbsp;|&nbsp; &copy; Verteville Energy {currentYear}. Designed and
          Developed by
          <a
            href="https://squeakfixtek.com/"
            target="blank"
            className="text-primary pl-1"
          >
            Squeakfix
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
