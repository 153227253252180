import { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import image1 from "../images/about1.jpg";

function Operation() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  return (
    <>
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5">
        <div data-aos="zoom-in">
          <h3 className="text-2xl md:text-4xl font-medium pb-1">
            Operations And Maintenance
          </h3>
          <h4 className="font-medium">
            <Link to="/" className="text-primary">
              Home
            </Link>{" "}
            / Maintenance
          </h4>
        </div>
      </div>

      <div
        className="myContainer grid grid-cols-1 lg:grid-cols-2 my-12 gap-4"
        data-aos="fade-up"
      >
        <div>
          <p className="pb-2">
            Operations and Maintenance Services provided by Verteville Energy
            are structured for the benefit of the owner to allow for complete
            plant or partial plant management of assets, be it equipment or
            personnel.
          </p>
          <p>
            Verteville Energy services can provide for personnel directly
            involved with to-day operations responsibility or for specific
            equipment coverage on a contract basis.
          </p>
          <h2 className="text-lg md:text-2xl py-3 font-semibold text-black">
             Verteville Energy O{"&"}M advantages
          </h2>
          <ol className="list-disc ml-5">
            <li>
              Complete personnel/equipment operation and MRO responsibility
              with    reports directly to ownership/management
            </li>
            <li>Output or product/power on a guaranteed basis</li>
            <li>Higher on-line performance</li>
            <li>Minimization of scheduled down-time</li>
            <li>
              Environmental issues compliance for emissions and other
              requirements
            </li>
          </ol>
        </div>

        <div>
          <img
            src={image1}
            alt="Operations And Maintenance"
            className="rounded-md mt-4 h-80"
          />
        </div>
      </div>
    </>
  );
}

export default Operation;
