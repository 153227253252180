import React from "react";
import { NavLink, useParams, Link } from "react-router-dom";
import PageNotFound from "./404";
import teamData from "../Components/teamData";

const data = teamData;

function PropertyDetail() {
  const { personName } = useParams();
  const person = data.find((p) => p.name === personName);
  let personData;

  if (person) {
    const {
      image,
      name,
      email,
      info1,
      info2,
      info3,
      info4,
      info5,
      info6,
      info7,
      info8,
      position,
    } = person;
    personData = (
      <>
        {/* Banner */}
        <div className="banner text-white flex justify-center text-center items-center px-5">
          <div>
            <h3 className="text-2xl md:text-4xl font-medium pb-1">{name}</h3>
            <h4 className="font-medium">
              <Link to="/team" className="text-primary">
                Back
              </Link>{" "}
              / Team Member
            </h4>
          </div>
        </div>
        <div className="myContainer my-12">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <div>
              <img src={image.default} alt={name} className="mb-3 rounded-lg" />
              <h4 className="text-lg font-medium">{email}</h4>
            </div>
            <div className="text-justify lg:col-span-2">
              <h2 className="text-3xl pb-2 font-semibold">{name}</h2>
              <h3 className="pb-3 text-lg font-medium">{position}</h3>
              <div className="bg-primary w-16 h-1 mb-3"></div>
              <div className="flex flex-col gap-3">
                <p>{info1}</p>
                <p>{info2}</p>
                <p>{info3}</p>
                <p>{info4}</p>
                <p>{info5}</p>
                <p>{info6}</p>
                <p>{info7}</p>
                <p>{info8}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    personData = <PageNotFound />;
  }

  return <div>{personData}</div>;
}

export default PropertyDetail;
