import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import map from "../images/map.png";

function Authorized() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  return (
    <>
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5">
        <div data-aos="zoom-in">
          <h3 className="text-2xl md:text-4xl font-medium pb-1 capitalize">
            Authorized Gas Shipper
          </h3>
          <h4 className="font-medium">
            <Link to="/" className="text-primary">
              Home
            </Link>
            / Operated by WAPCo
          </h4>
        </div>
      </div>

      <div className="myContainer grid grid-cols-1 lg:grid-cols-2 my-12 gap-4">
        <div>
          <p className="text-justify">
            <b className="capitalize pr-1">
              Authorized gas shipper on the West Africa gas pipeline (WAGP)
              operated by WAPCo:
            </b>
            Monitors the gas delivery process in line with the
            existing/potential offtaker's DCQ from the gas entry point into the
            WAGP up to the exit point out of WAGP.
          </p>

          <ol className="list-disc ml-5 mt-4 text-justify">
            <li>
              Our synergy with industry professionals ensures our unfailing
              reliability.
            </li>
            <li>
              We facilitate the utilization of natural gas by managing the
              contractual volume (ACQ/DCQ) and efficiently handling nomination,
              scheduling and allocation processes.
            </li>
            <li>
            Verteville ensures seamless operations and effective allocation of resources, providing the energy required to meet the region's demand.
            </li>
            <li>
            Verteville plays a pivotal role in the transportation system by ensuring the seamless delivery of gas from gas producers to potential off-takers along the WAGP corridor through executing GSA and/or GTA
            </li>
          </ol>
        </div>

        <div>
          <img
            src={map}
            alt="Authorized Gas Shipper"
            className="rounded-md h-80"
          />
        </div>
      </div>
    </>
  );
}

export default Authorized;
