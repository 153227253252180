import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

function TeamInAbout({data}) {
  const { url } = useRouteMatch();
  const { image, name } = data;
  return (
    <div>
      <div className="card shadow-md">
        <div className="img-box">
          <img src={image.default} alt={name} />
        </div>
        <div className="content bg-primary text-white">
          <h2 className="pt-5 text-xl font-medium">{name}</h2>
          <p>
            <Link
              to={`${url}/${name}`}
              className="border-2 py-2 px-4 text-lg hover:bg-secondary"
            >
              View Bio
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default TeamInAbout;
