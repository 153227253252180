import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider1 from "../images/verte-power-1024x768-1024x768.jpg";
import slider2 from "../images/transformer-1024x410.jpg";
import slider3 from "../images/OM-1024x576.jpg";
import slider4 from "../images/power-1024x562.jpg";

function SampleNextArrow(props) {
  const { style } = props;
  return <div style={{ ...style, display: "none" }} />;
}

function SamplePrevArrow(props) {
  const { style } = props;
  return <div style={{ ...style, display: "none" }} />;
}

const HomeSlider = () => {
  const settings = {
    className: "",
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="text-white">
      <Slider {...settings}>
        <div>
          <div
            className="px-4 md:pl-12 grid grid-cols-1 md:grid-cols-2 h-carousel"
            style={{ backgroundImage: `url(${slider1})` }}
          >
            <div>
              <h1 className="text-2xl sm:text-2xl md:text-4xl font-semibold pb-2 uppercase">
                Transformer Specialist
              </h1>
              <h3 className="pb-5 text-md md:text-lg">
                Verteville transformer technicians are trained specialist who
                have extensive knowledge of transformer operations and
                maintenance.
              </h3>
            </div>
          </div>
        </div>

        <div>
          <div
            className="px-4 md:pl-12 grid grid-cols-1 md:grid-cols-2 h-carousel"
            style={{ backgroundImage: `url(${slider2})` }}
          >
            <div>
              <h1 className="text-2xl sm:text-2xl md:text-4xl font-semibold pb-2 uppercase">
                Industrial Gas Turbine Solutions
              </h1>
              <h3 className="pb-5 text-md md:text-lg">
                Gas Turbine MRO Customized solutions and services for
                flexibility and freedom from the O&M
              </h3>
            </div>
          </div>
        </div>

        <div>
          <div
            className="px-4 md:pl-12 grid grid-cols-1 md:grid-cols-2 h-carousel"
            style={{ backgroundImage: `url(${slider3})` }}
          >
            <div>
              <h1 className="text-2xl sm:text-2xl md:text-4xl font-semibold pb-2 uppercase">
                Replacement Units
              </h1>
              <h3 className="pb-5 text-md md:text-lg">
                {" "}
                VERTEVILLE has engineered direct replacement units for aging and
                obsolete, or unsupported O&M controllers
              </h3>
            </div>
          </div>
        </div>

        <div>
          <div
            className="px-4 md:pl-12 grid grid-cols-1 md:grid-cols-2 h-carousel"
            style={{ backgroundImage: `url(${slider4})` }}
          >
            <div>
              <h1 className="text-2xl sm:text-2xl md:text-4xl font-semibold pb-2 uppercase">
                EPC Power Plant
              </h1>
              <h3 className="pb-5 text-md md:text-lg">
                {" "}
                Customized solutions and services for flexibility and freedom
                from the O&M Engineering • Procurement • Construction
              </h3>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default HomeSlider;
