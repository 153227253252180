import { useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import teamData from "../Components/teamData";
import Aos from "aos";
import "aos/dist/aos.css";

function Team() {
  const { url } = useRouteMatch();
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  return (
    <>
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5">
        <div data-aos="zoom-in">
          <h3 className="text-2xl md:text-4xl font-medium pb-1">The Team</h3>
          <h4 className="font-medium">
            <Link to="/" className="text-primary">
              Home
            </Link>{" "}
            / Team
          </h4>
        </div>
      </div>
      <div className="myContainer mt-12 mb-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full mx-auto text-center">
          {teamData.map((data) => {
            const { name, image, position, id } = data;
            return (
              <div
                key={id}
                className="border py-6 bg-white hover:shadow-lg"
                data-aos="zoom-in"
              >
                <div className="flex justify-center mb-5">
                  <img
                    src={image.default}
                    alt={name}
                    className="h-32 w-h-32 rounded-full"
                  />
                </div>
                <Link to={`${url}/${name}`}>
                  {" "}
                  <h3 className="text-xl pb-3 hover:text-primary">
                    {name}
                  </h3>{" "}
                </Link>
                <h4 className="text-base">{position}</h4>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Team;
