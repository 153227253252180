import { useEffect } from "react";
import { Link } from "react-router-dom";
import image1 from "../images/st.png";
import image2 from "../images/banner.png";
import Aos from "aos";
import "aos/dist/aos.css";

function Steam() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);

  return (
    <>
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5">
        <div data-aos="zoom-in">
          <h3 className="text-2xl md:text-4xl font-medium pb-1">
            Turbine Compressor Service
          </h3>
          <h4 className="font-medium">
            <Link to="/" className="text-primary">
              Home
            </Link>{" "}
            / Steam Turbine
          </h4>
        </div>
      </div>

      <div className="myContainer grid grid-cols-1 lg:grid-cols-2 gap-8 my-12 text-secondary">
        <div data-aos="fade-up">
          <p>
            Customized solutions and services for flexibility and freedom from
            the O&M MRO Services
          </p>
          <img
            src={image1}
            alt="Customized solutions"
            className="rounded-md h-96 mb-4 pt-2"
          />
          <p className="text-justify">
            Cost Competitive Alternative VERTEVILLE is an experienced steam
            turbine solutions provider for mechanical and controls services for
            a wide range of models and sizes. VERTEVILLE specializes in
            providing cost-effective MRO solutions for steam turbines.
            VERTEVILLE can offer a full range of mechanical services and
            upgrades for control retrofits at relatively low cost.
          </p>
          <img
            src={image2}
            alt="Customized solutions"
            className="rounded-lg h-80 mb-4 pt-4"
          />
        </div>

        <div data-aos="fade-up">
          <h2 className="text-xl pb-5 font-semibold text-black">
            Steam Turbine Mechanical Services
          </h2>
          <p>
            VERTEVILLE has highly experienced technical staff who are
            O&M-trained turbine technicians. They support all steam turbine and
            generator inspections, repairs, and maintenance.
          </p>

          <p className="py-2 font-semibold text-black">
            Selected steam turbine services:
          </p>
          <ol className="list-disc mb-3 ml-5">
            <li>Major and minor overhauls, repairs, and inspections</li>
            <li>Generator inspection and testing Steam path audits</li>
            <li>Borescope</li>
            <li>High velocity oil flushes</li>
            <li>Onsite electrical testing</li>
            <li>At speed balancing</li>
          </ol>
          <p className="text-justify">
            VERTEVILLE deploys its own technical directors, supervisors,
            foremen, crew, and tool units to ensure that the highest levels of
            quality and safety are met within the required time and budget.
            VERTEVILLE field engineers are also available for emergency support
            worldwide to support turbine, generator, compressor, auxiliary, and
            control systems.
          </p>

          <h2 className="text-xl pb-5 font-semibold py-2 text-black">
            Selected Steam Turbine Experience
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <ol className="list-disc ml-5">
              <li>ABB Alstom</li>
              <li>AEG Kanis</li>
              <li>Brown Boveri</li>
              <li>Dresser</li>
              <li>Elliott</li>
              <li>General Electric</li>
            </ol>
            <ol className="list-disc">
              <li>Koenisberg</li>
              <li>LMZ</li>
              <li>Siemens</li>
              <li>Sulzer</li>
              <li>Westinghouse</li>
              <li>Zorya</li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}

export default Steam;
