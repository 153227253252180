import { useEffect } from "react";
import { Link } from "react-router-dom";
import image1 from "../images/R1.png";
import image2 from "../images/R.png";
import image3 from "../images/snip2.png";
import Aos from "aos";
import "aos/dist/aos.css";

function Replacement() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  return (
    <>
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5">
        <div data-aos="zoom-in">
          <h3 className="text-2xl md:text-4xl font-medium pb-1">
            Replacement Units
          </h3>
          <h4 className="font-medium">
            <Link to="/" className="text-primary">
              Home
            </Link>{" "}
            / Replacement
          </h4>
        </div>
      </div>

      <div className="myContainer mt-12">
        <div
          className="text-center text-lg md:text-2xl pb-5 font-semibold text-black mb-3"
          data-aos="zoom-in"
        >
          <h2>Plug-and-Play Control Units</h2>
          <h2 className="py-2">
            Customized solutions and services for flexibility and freedom from
            the O&M
          </h2>
          <h2>DIGICON®• NetCon™ 5000 • Rustronic℠</h2>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            <img
              src={image1}
              alt="Replacement Units"
              className="rounded rounded-md h-96 mb-4"
              data-aos="fade-up"
            />
          </div>
          <div data-aos="fade-up">
            <p>
              VERTEVILLE has engineered direct replacement units for aging and
              obsolete, or unsupported O&M controllers
            </p>
            <img
              src={image2}
              alt="Replacement Units"
              className="rounded rounded-md h-80 mb-4"
            />
          </div>
        </div>
        <div className="py-5">
          <div className="flex flex-col gap-1">
            <b>HSDE DIGICON Series 1, 2, and 3</b>
            <b>Woodward NetCon 5000</b>
            <b>
              {" "}
              Rustronic (also known as EGT or Siemens) MKI, MKII, and NORBIT
            </b>
          </div>
          <p>
            These control solutions include precise automatic governing,
            sequencing and load control, regardless of industry or application.
            The VERTEVILLE control systems have been proven to dramatically
            increase start availability, reliability, and performance when
            compared to analog- relay or hydro-mechanical systems. It also has
            extensive diagnostics and communication facilities allowing for easy
            interface with other systems, including SCADA or DCS. These systems
            are safe and cost effective, providing a flexible solution to any
            application.
          </p>
        </div>

        <img
          src={image3}
          alt="Replacement Units"
          className="rounded rounded-sm mb-12"
        />
      </div>
    </>
  );
}

export default Replacement;
