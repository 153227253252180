import { Route, Switch } from "react-router";
import Layout from "./Components/Layout"
import Home from "./Pages/Home";
import About from "./Pages/About";
import Transformer from "./Pages/Transformer";
import Stream from "./Pages/Steam";
import Replacement from "./Pages/Replacement";
import Power from "./Pages/Power";
import Operation from "./Pages/Operation";
import Industry from "./Pages/Industrial";
import Team from "./Pages/Team";
import Contact from "./Pages/Contact";
import NotFoundPage from "./Pages/404";
import SingleTeam from "./Pages/SingleTeam";
import Authorized from "./Pages/Authorized";
import Policy from "./Pages/Policy";



function App() {
  return (
 
    <Layout>
       <Switch>
         <Route exact path="/"> <Home/> </Route>
         <Route path="/about" exact> <About/> </Route>
         <Route path="/about/:personName"> <SingleTeam /> </Route>
         <Route path="/transformer"> <Transformer/> </Route>
         <Route path="/steam"> <Stream/> </Route>
         <Route path="/replacement"> <Replacement/> </Route>
         <Route path="/operation"> <Operation /> </Route>
         <Route path="/authorized"> <Authorized /> </Route>
         <Route path="/power"> <Power/> </Route>
         <Route path="/industry"> <Industry/> </Route>
         <Route path="/team" exact> <Team /> </Route>
         <Route path="/team/:personName"> <SingleTeam /> </Route>
         <Route path="/contact"> <Contact/> </Route>
         <Route path="/policy"> <Policy/> </Route>
         <Route> <NotFoundPage/> </Route>
       </Switch>
    </Layout>
  
  );
}
 
export default App;
