import { useEffect } from "react";
import { Link } from "react-router-dom";
import image1 from "../images/tu.png";
import image2 from "../images/ep.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

function Industrial() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  return (
    <>
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5">
        <div data-aos="zoom-in">
          <h3 className="text-2xl md:text-4xl font-medium pb-1">
            Industrial GT Solutions
          </h3>
          <h4 className="font-medium">
            <Link to="/" className="text-primary">
              Home
            </Link>{" "}
            / Industrial GT
          </h4>
        </div>
      </div>

      <div className="myContainer my-12">
        <div
          className="text-justify md:text-center text-lg md:text-2xl pb-5 font-semibold text-black mb-3"
          data-aos="fade-up"
        >
          <h2>
            Industrial Gas Turbine Solutions, <br className="hidden lg:flex" />{" "}
            Gas Turbine MRO Customized solutions and services{" "}
            <br className="hidden lg:flex" /> for flexibility and freedom from
            the O&M
          </h2>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-6">
          <div>
            <h2 className="text-xl pb-5 font-semibold text-black">
              Heavy Industrial Turbine
            </h2>

            <img
              src={image1}
              alt="Industrial GT Solutions"
              className="rounded rounded-md mb-4"
              data-aos="fade-up"
            />
            <h2 className="text-xl py-4 font-semibold text-black">
              Industrial Mechanical Services
            </h2>
            <p className="text-justify" data-aos="fade-up">
              VERTEVILLE has O&M-trained turbine technicians, who support all
              turbine-associated equipment and can provide guidance on managing
              the life of the engine. VERTEVILLE performs major and minor
              turbine overhaul (MTO) on various rotating equipment, such as GE
              and Westinghouse gas centrifugal compressors and generators.
              VERTEVILLE field engineers are available for emergency support
              worldwide to support turbine, generator, compressor, auxiliary,
              and control systems.
            </p>

            <h2 className="text-xl py-4 font-semibold text-black">
              Overview of Mechanical and Field Services
            </h2>
            <ol className="ml-5 list-disc" data-aos="fade-up">
              <li>Major inspections/overhauls</li>
              <li>Hot gas path inspections</li>
              <li>Combustion inspections</li>
              <li>Borescope inspections</li>
              <li>
                Staff augmentation for major repairs/upgrade projects or ongoing
                maintenance Component repair
              </li>
              <li>Parts planning and outage tracking reports</li>
            </ol>
          </div>

          <div className="lg:ml-10">
            <h2 className="text-xl pb-2 font-semibold text-black">
              Models of Gas Turbines Serviced
            </h2>
            <ol className="ml-5 list-disc mb-3" data-aos="fade-up">
              <li>GE Frame 3 – all models</li>
              <li>GE Frame 5 – single- and twin-shaft models</li>
              <li>A, B, C, D, J, K, L, L/A, M, N, P</li>
              <li>GE Frame 6 – models A, B</li>
              <li>GE Frame 7 – models B, B/C, C, E, EA, FA</li>
              <li>Westinghouse 101, 191, 251, 301 – all models</li>
              <li>Siemens V84.2/94.2</li>
              <li>Combined cycle units up to 170 MW</li>
            </ol>

            <h2 className="text-xl pb-2 font-semibold text-black">
              Generator Field Services
            </h2>
            <ol className="ml-5 list-disc mb-3" data-aos="fade-up">
              <li>Repair and rewind of generators and HV motors</li>
              <li>
                Shop- and site-based emergency and scheduled services rotor and
                stator rebuilds and maintenance
              </li>
              <li>Onsite electrical testing</li>
              <li>At speed balancing</li>
              <li>Outage management</li>
            </ol>

            <h2 className="text-xl pb-2 font-semibold text-black">
              Additional Services
            </h2>
            <ol className="ml-5 list-disc" data-aos="fade-up">
              <li>Blasting</li>
              <li>Non-destructive examinations (NDE)</li>
              <li>Inlet and exhaust repair and procurement</li>
              <li>Accessory gearbox inspection and overhaul</li>
              <li>Controls troubleshooting</li>
            </ol>
            <img
              src={image2}
              alt="Industrial GT Solutions"
              className="rounded rounded-md mt-4"
              data-aos="fade-up"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Industrial;
