import React, { useState } from "react";

const Policy = () => {
  const [activeTab, setActiveTab] = useState("hse"); // Use state to track active tab

  return (
    <div className="myContainer my-10">
      <div className="flex justify-between mb-6">
        {/* Tab buttons */}
        <button
          onClick={() => setActiveTab("hse")}
          className={`${
            activeTab === "hse" ? "bg-primary text-white" : "bg-gray-200 text-gray-700"
          } px-4 py-2 rounded-l-md focus:outline-none transition duration-300 hover:bg-primary hover:text-white`}
        >
          HSE POLICY
        </button>
        <button
          onClick={() => setActiveTab("quality")}
          className={`${
            activeTab === "quality" ? "bg-primary text-white" : "bg-gray-200 text-gray-700"
          } px-4 py-2 rounded-r-md focus:outline-none transition duration-300 hover:bg-primary hover:text-white`}
        >
          QUALITY POLICY
        </button>
      </div>

      {/* Tab content */}
      {activeTab === "hse" && (
        <div>
          <h2 className="text-2xl font-semibold mb-4">HSE POLICY STATEMENT</h2>
          <p className="mb-4">
            Verteville Energy Company Limited recognizes the importance of conducting its business
            activities in such a manner that safeguards the health and safety of its employees,
            contractors and other persons directly or indirectly involved in its operation and the
            sustenance of the environment in which it operates.
          </p>
          <p className="mb-4">
            As a company engaged in engineering services, we believe the prevention of incidents, ill
            health and protection of the environment are critical to the efficient conducts of its
            operations.
          </p>
          <p className="mb-4">
            To this end, Health, Safety and Environmental issues shall be given utmost priority on
            our drive to attain operational excellence by implementing the following:
          </p>
          <ul className="list-disc ml-8 mb-4">
            <li>Maintaining and improving safety interest and awareness through induction, regular safety briefings, safety drills, and training of all levels of staff on safety matters.</li>
            <li>Verteville Energy Company Limited shall provide visible leadership, resources, and effective communication to its entire workforce.</li>
            <li>Verteville Energy Company Limited shall comply with legal, statutory requirements as well as corporate governance best practices.</li>
            <li>Conduct HSE audits, Risk Assessments, Inspections, Environmental Studies, Incident reporting, and other safety-related programs where required shall be conducted for the identification, mitigation, and elimination of hazards and unsafe practices associated with our operations.</li>
            <li>Responsibility for compliance with Verteville Energy Company Limited policy resides with the line managers who are accountable for safety in their various sites including the entire staff, assets, and persons involved in our operation. Everyone is held accountable for safety in the company.</li>
            <li>Corporate HSE goals and objectives shall be set and benchmarked against our yearly performance in an effort to promote a culture of continuous improvement.</li>
          </ul>
          <p className="mb-4">
            This policy is made available to interested parties through the company’s website, communication portals, and notice boards. It is imperative that everyone within the company’s operations take ownership of this policy for their personal safety and that of others.
          </p>
          <p>
            This policy shall be reviewed annually by management to ensure that it remains relevant to its operations and the scope of Occupational Health and Safety.
          </p>
        </div>
      )}

      {activeTab === "quality" && (
        <div>
          <h2 className="text-2xl font-semibold mb-4">QUALITY POLICY STATEMENT</h2>
          <p className="mb-4">
            Verteville Energy Company Limited is committed to achieving customer satisfaction through the delivery of world-class products and services and the continual improvement of its business processes.
          </p>
          <p className="mb-4">
            The management of Verteville demonstrates a system of control by:
          </p>
          <ul className="list-disc ml-8 mb-4">
            <li>Providing products and services that meet and exceed customer expectations;</li>
            <li>Operating effective and efficient processes that align with our vision and strategies;</li>
            <li>Maintaining a quality management system that complies with ISO 9001:2015 standard and other statutory & regulatory requirements;</li>
            <li>Systematically reviewing and improving the quality of our products, services, and operations;</li>
            <li>Providing a framework for setting quality objectives and benchmarking against yearly performance to promote a culture of continual improvement;</li>
          </ul>
          <p className="mb-4">
            This policy is communicated and understood by all employees and is made available to all interested parties as appropriate through the company’s portal and notice boards. It is also imperative that everyone under our operations take ownership of this policy by identifying opportunities for improvement and taking corrective actions when necessary.
          </p>
          <p>
            This policy shall be reviewed annually by management to ensure continuous suitability and improvement.
          </p>
        </div>
      )}
    </div>
  );
};

export default Policy;
