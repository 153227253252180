export const serviceData = [
  {
    id: 1,
    title: "Turbine Compressor Services",
    desc: "Verteville has highly experienced technical staff who are O&M-trained turbine technicians. They support all steam turbine and generator inspections, repairs",
    to: "/steam",
  },
  {
    id: 2,
    title: "Industrial GT Solutions",
    desc: "Verteville has O&M-trained turbine technicians, who support all turbine-associated equipment and can provide guidance on",
    to: "/industry",
  },
  {
    id: 3,
    title: "Replacement Units",
    desc: "These control solutions include precise automatic governing, sequencing and load control, regardless of industry or application",
    to: "/replacement",
  },
  {
    id: 4,
    title: "Power Plant EPC",
    desc: "Verteville can design a power plant utilizing a wide-range of industrial or aeroderivative gas-turbine generator sets for simple",
    to: "/power",
  },
  {
    id: 5,
    title: "O&M",
    desc: "Operations and maintenance services provided by Verteville Energy are structured for the benefit of the owner to allow for complete plant",
    to: "/operation",
  },
  {
    id: 6,
    title: "Transformer Reliability",
    desc: "Verteville transformer technicians are trained specialist who have extensive knowledge of transformer operations and maintenance",
    to: "/transformer",
  },
  {},
  {
    id: 7,
    title:
      "Authorized Gas Shipper on the West African gas pipeline (WAGP) Operated by WAPCo",
    desc: "Monitors the gas delivery process in line with the existing/potential offtaker's DCQ from the gas entry point into the WAGP up to the exit point out of WAGP.",
    to: "/authorized",
  },
  
];
