import { useState } from "react";
import "./navbar.css";
import logo from "../images/logo.png";
import { NavLink } from "react-router-dom";

function Navbar() {
  const [click, setClick] = useState(true);

  const closeNav = () => {
    setClick(!click);
  };

  return (
    <>
      {/* top section Desktop  */}
      <div className="flex justify-center gap-12 text-white py-4 font-medium bg-primary topSection">
        <div className="flex gap-2 items-center">
        <i className="ri-phone-line text-xl"></i>
          <div>
            <span className="block font-light">Call us :</span>
            <a href="tel:+234 807 769 1710" className="hover:text-black">
              +234 807 769 1710
            </a>
          </div>
        </div>
        <div className="flex gap-2 items-center">
        <i className="ri-mail-line text-xl"></i>
          <div>
            <span className="block font-light">Email us :</span>
            <a
              href="mailto:info@vertevilleenergy.com"
              className="racking-wider hover:text-black"
            >
              info@vertevilleenergy.com
            </a>
          </div>
        </div>
        <div className="flex gap-2 items-center">
        <i className="ri-time-line text-xl"></i>
          <div>
            <span className="block font-light">Opening Hours:</span>
            <span>Mon-Fri: 8am – 5pm</span>
          </div>
        </div>

        <div className="flex items-center gap-2">
          <i className="ri-map-pin-line text-xl"></i>
          <div>
            <span className="block">1435A Sanusi Fafunwa Street,</span>
            <span>Victoria Island Lagos.</span>
          </div>
        </div>
      </div>
      {/* main Navbar */}
      <nav className="shadow w-full sticky top-0 bg-white">
        <NavLink to="/" className="logo">
          <img src={logo} alt="" className="h-16 pt-2" />
        </NavLink>
        <label htmlFor="btn" className="icon" onClick={closeNav}>
          <span className="fa fa-bars"></span>
        </label>
        <input type="checkbox" id="btn" />
        <ul className={click ? "open" : "ul"}>
          <li>
            <NavLink
              to="/"
              exact={true}
              activeClassName="is-active"
              onClick={closeNav}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" activeClassName="is-active" onClick={closeNav}>
              About
            </NavLink>
          </li>
          <li>
            <label htmlFor="btn-1" className="show">
              Services +
            </label>
            <NavLink to="#!">Services</NavLink>
            <input type="checkbox" id="btn-1" />
            <ul>
              <li>
                <NavLink to="/transformer" onClick={closeNav}>
                  Transformer Reliability
                </NavLink>
              </li>
              <li>
                <NavLink to="/steam" onClick={closeNav}>
                  Turbine Compressor Service
                </NavLink>
              </li>
              <li>
                <NavLink to="/replacement" onClick={closeNav}>
                  Replacement Units
                </NavLink>
              </li>

              <li>
                <NavLink to="/power" onClick={closeNav}>
                  Power Plant EPC
                </NavLink>
              </li>
              <li>
                <NavLink to="/operation" onClick={closeNav}>
                  Operations and Maintenance
                </NavLink>
              </li>
              <li>
                <NavLink to="/industry" onClick={closeNav}>
                  Industrial GT Solutions
                </NavLink>
              </li>
              <li>
                <NavLink to="/authorized" onClick={closeNav}>
                  Authorized Gas Shipper
                </NavLink>
              </li>
            </ul>
          </li>

          <li>
            <NavLink to="/team" activeClassName="is-active" onClick={closeNav}>
              The Team
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              activeClassName="is-active"
              onClick={closeNav}
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
