import { useEffect } from "react";
import HomeSlider from "../Components/HomeSlider";
import about1 from "../images/f2322f15-7239-4a80-b865-59b5e7cbd246.jpg";
import about2 from "../images/about2.png";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { serviceData } from "../Helpers/serviceData";

function Home() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  const cardStyle =
    "bg-white py-3 px-3 shadow transition ease-in-out duration-500 hover:shadow-lg";
  return (
    <div>
      <HomeSlider />

      {/* about */}
      <div
        className="myContainer grid grid-cols-1 md:grid-cols-2 gap-6 my-10 lg:my-20"
        data-aos="fade-up"
      >
        <div>
          <h1 className="text-xl md:text-3xl lg:text-4xl font-semibold pb-3">
            About <span className="text-primary">Verteville Energy</span>
          </h1>
          <p className="text-secondary text-justify">
            Verteville energy company limited is a global energy company
            delivering exceptional value to Clients. our value-driven process,
            strong supplier network, and experience in the energy industry give
            us the enabler to provide exceptional services to our Client......
          </p>
          <button className="button mt-4 cursor-pointer text-white text-center border-none bg-primary">
            {" "}
            <Link to="/about">
              {" "}
              <span>Read more</span>{" "}
            </Link>
          </button>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <img src={about1} alt="about Verteville Energy" />
          <img
            src={about2}
            alt="about Verteville Energy"
            className="hidden lg:flex mt-6"
          />
        </div>
      </div>

      {/* services */}
      <div className="myContainer mb-24">
        <h1 className="text-xl md:text-3xl lg:text-4xl font-semibold pb-4">
          Our Services
        </h1>
        <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {serviceData.map((service) => {
            return (
              <div
                className={service.id ? cardStyle : ""}
                key={service.id}
                data-aos="zoom-in"
              >
                <h3 className="pb-2 text-lg lg:text-2xl font-medium capitalize">
                  {service.title}
                </h3>
                <p className="text-secondary text-base text-justify">
                  {service.desc}
                </p>
                {service.id && (
                  <Link to={service.to} className="flex gap-2 mt-3">
                    <span className="bg-primary rounded-full text-white p-1">
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-primary">Read more..</span>
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Home;
