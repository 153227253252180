import { useEffect } from "react";
import { Link } from "react-router-dom";
import about1 from "../images/f2322f15-7239-4a80-b865-59b5e7cbd246.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import teamData from "../Components/teamData";
import TeamInAbout from "../Components/TeamInAbout";

function About() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);

  // team mapping and slice
  const team = teamData.map((data) => (
    <TeamInAbout data={data} key={data.id} />
  ));
  const selectedTeam = team.slice(1, 5);

  return (
    <>
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5">
        <div data-aos="zoom-in">
          <h3 className="text-2xl md:text-4xl font-medium pb-1">
            Industrial GT Solutions
          </h3>
          <h4 className="font-medium">
            <Link to="/" className="text-primary">
              Home
            </Link>{" "}
            / Industrial GT
          </h4>
        </div>
      </div>
      <div className="myContainer">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 my-12">
          <img src={about1} alt="about Verteville Energy" data-aos="fade-up" />
          <div className="col-span-2 text-justify" data-aos="fade-up">
            <h1 className="text-xl md:text-3xl lg:text-4xl font-semibold pb-3">
              About <span className="text-primary">Verteville Energy</span>
            </h1>
            <p>
              Company Limited is a Global Energy Company  delivering exceptional
              value to Clients. Our value-driven process, strong supplier
              network, and experience in the Energy Industry give us the enabler
              to provide exceptional services to our Client.
            </p>
            <p className="py-3">
              Over the years, we have successfully undertaken various projects
              that reflect our strong hold in the industry ranging from
              Electricity, Oil and Gas (upstream and downstream) etc. We have
              provided professional energy consulting services to industrial
              end-users, commercial end-users and utility retailers. Our
              approach is unique in that we develop an array of customised
              solutions to fit each client’s individual needs.
            </p>
            <p>
              We provide relief for plant and facility managers with bottom line
              responsibility.  We help corporate energy managers fill gaps in
              their in-house capabilities or expertise. We also do operation and
              maintenance of Plants of different sizes and capacities.
            </p>
            <p className="pt-3">
              Verteville’s utility and industrial clients also take advantage of
              Verteville’s regulatory expertise.  Here again we offer a menu of
              capabilities from which our clients select according to their
              needs.  The areas of competence of Verteville Energy in
              Electricity Sector include Generation; Distribution and
              Transmission.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-5 text-center">
          <div className="bg-gray-100 pt-3 pb-6 px-4 border-2 rounded shadow-sm">
            <div className="flex justify-center">
              <i className="fas fa-rocket text-white text-2xl bg-primary rounded-full mb-5 mt-3 flex justify-center items-center w-14 h-14"></i>
            </div>
            <h3 className="font-medium tracking-wide text-xl pb-3">
              MISSION STATEMENT
            </h3>
            <div className="font-medium tracking-wide text-base text-justify">
              <p>
                To be a model service provider in the energy sector in Nigeria and
                Africa by balancing operational excellence, stakeholder value,
                safety and environmental performances, people engagement and
                community leadership.
                
              </p>
            </div>
          </div>
          <div className="bg-gray-100 pt-3 pb-6 px-4 border-2 rounded shadow-sm">
            <div className="flex justify-center">
              <i className="far fa-eye text-white text-2xl bg-primary rounded-full mb-5 mt-3 flex justify-center items-center w-14 h-14"></i>
            </div>
            <h3 className="font-medium tracking-wide text-xl pb-3">
              OUR VISION
            </h3>
            <div className="font-medium tracking-wide text-base">
              <p>To Provide Real Energy Solutions in Africa</p>
            </div>
          </div>

          <div className="bg-gray-100 pt-3 pb-6 px-4 border-2 rounded shadow-sm">
            <div className="flex justify-center">
              <i className="fas fa-star-of-david text-white text-2xl bg-primary rounded-full mb-5 mt-3 flex justify-center items-center w-14 h-14"></i>
            </div>
            <h3 className="font-medium tracking-wide text-xl pb-3">
              OUR CORE VALUES
            </h3>
            <div className="font-medium tracking-wide text-base">
              <ol className="list-disc pl-24 pr-10 lg:pl-28 lg:pr-14">
                <li>Safety</li>
                <li>Integrity</li>
                <li>Commitment</li>
                <li>Competence</li>
                <li>Simplified Solution</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {/*the Team */}
      <div className="bg-white mb-5">
        <h2 className="text-center py-10 font-medium text-3xl uppercase">
          Our Team
        </h2>
      </div>

      <div className="myContainer mb-12 text-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {selectedTeam}
        </div>
      </div>
    </>
  );
}

export default About;
