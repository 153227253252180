import { useEffect } from "react";
import { Link } from "react-router-dom";
import image1 from "../images/oil.png";
import image2 from "../images/about2.png";
import Aos from "aos";
import "aos/dist/aos.css";

function Transformer() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  return (
    <>
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5">
        <div data-aos="zoom-in">
          <h3 className="text-2xl md:text-4xl font-medium pb-1">
            Transformer Reliability
          </h3>
          <h4 className="font-medium">
            <Link to="/" className="text-primary">
              Home
            </Link>{" "}
            / Transformer
          </h4>
        </div>
      </div>

      <div className="myContainer grid grid-cols-1 lg:grid-cols-2 gap-8 my-12">
        <div data-aos="fade-up">
          <h2 className="text-2xl pb-5 font-semibold">
            Benefits of Regenerating Transformer oil
          </h2>
          <img
            src={image1}
            alt="Transformer oil"
            className="rounded rounded-md h-96 mb-4"
          />
          <div className="text-secondary text-justify">
            <p>
              <b>Life extension of a limited resource</b> – Regeneration
              technology has a long history which shows that recycled oil is as
              good as, if not better than new. The same oil that has been aged,
              regenerated, aged and regenerated 6 times shows no decrease in
              efficiency showing an effective lifetime for oil-in use of over
              100 years.
            </p>
            <p className="py-2">
              <b>Economically advantageous</b> – The price of purchasing
              regenerated oil is typically less than 80% of new oil and can be
              as low as 50%. Ancillary savings increment when considering there
              is no downtime when reclaiming on energized equipment. Replacement
              with new oil and hot oil flush requires equipment switch off.
            </p>
            <p>
              <b>Control of strategic asset is retained</b> – Oil in use is an
              asset. Use of regeneration technology removes dependence on oil
              companies to deliver replacement supplies and isolates from wildly
              fluctuating external market prices.
            </p>
            <p className="pt-2">
              <b>Removal of corrosive sulfur</b> – Lower quality transformer oil
              contains DBDS (Dibenzyl Disulfide) as corrosive sulfur. Corrosive
              sulfur has been identified as the cause of several transformer
              failures in recent years. Fully regenerating using the Verteville
              TORS removes DBDS and obviates the requirement for passivating the
              oil, reducing oil maintenance costs and providing peace of mind
            </p>
          </div>
        </div>

        <div data-aos="fade-up">
          <h2 className="text-2xl pb-5 font-semibold">
            Transformer Specialist
          </h2>

          <p>
            Verteville transformer technicians are trained specialist who have
            extensive knowledge of transformer operations and maintenance.
          </p>

          <h2 className="text-2xl py-2 font-semibold">Supporting Services</h2>
          <ol className="list-disc ml-5">
            <li>Vacuuming</li>
            <li>Filtration</li>
            <li>On/Off line Regeneration</li>
            <li>Sealing of leakages</li>
            <li>Oil Analysis/Interpretation</li>
            <li>Electrical Testing</li>
          </ol>
          <img
            src={image2}
            alt="Transformer oil"
            className="rounded rounded-md my-5"
          />
          <p className="text-secondary text-justify">
            {" "}
            <b>Processing on energized transformers</b> – The Verteville Energy
            TORS unit is designed to operate on energized transformers as
            effectively as on un-energized transformers. There is no loss of
            income, as there would be for an oil changeout, if the transformer
            is processed energized. The additional advantage of this process is
            that the transformer core is treated at the same time and the
            transformer can be completely desludged – a benefit not accrued with
            retro filling.
          </p>
        </div>
      </div>
    </>
  );
}

export default Transformer;
